<template>
  <v-card class="rounded-lg no-scroll dialog-card pa-0" flat>
    <div class="dialog-card">
      <v-row
        class="ma-0 mb-6 movements-header"
        justify="space-between"
        align="center"
      >
        <div>
          <span class="member-name mr-4">{{ truncatedName }}</span>
          <span class="date-range">{{ formattedDateRange }}</span>
        </div>
        <div class="d-flex align-center">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-2 button"
                outlined
                large
                @click="dateMenu = !dateMenu"
              >
                <v-icon size="20" class="mr-1">mdi-calendar-outline</v-icon>
                <span class="button-text">Rango de Fechas</span>
              </v-btn>
            </template>
            <v-date-picker
              v-model="dateRange"
              range
              locale="es"
              no-title
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                outlined
                large
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20" class="mr-1">mdi-filter-outline</v-icon>
                <span class="button-text">Filtrar</span>
              </v-btn>
            </template>

            <v-card color="white" class="pa-2" width="400">
              <v-row class="ma-0 my-2" justify="space-between" align="center">
                <v-btn class="mr-2" outlined small @click="resetFilters()">
                  <span class="filter-button">Borrar</span>
                </v-btn>

                <span class="mr-2 font-weight-bold">Filtros</span>

                <v-btn
                  class="mr-2"
                  small
                  color="#1b273e"
                  @click="getMemberMovements()"
                >
                  <span class="filter-button white--text">Listo</span>
                </v-btn>
              </v-row>

              <hr />

              <v-row class="py-2">
                <v-col
                  class="py-1 filter-item"
                  cols="12"
                  v-for="category in categories"
                  :key="category"
                >
                  <v-checkbox
                    hide-details
                    class="shrink"
                    dense
                    multiple
                    color="#1b273e"
                    v-model="filters"
                    :label="category"
                    :value="category"
                  >
                    <template v-slot:label>
                      <span class="black--text wc-caption-2">{{
                        category
                      }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <!-- Dialog para crear movimientos  -->
          <v-dialog
            v-model="newMovementDialog"
            class="dialog-card-auto white"
            width="30%"
            @click:outside="resetForm()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                large
                color="#1b273e"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white" size="20" class="mr-1">mdi-plus</v-icon>
                <span class="button-text white--text">Nuevo Movimiento</span>
              </v-btn>
            </template>

            <v-card color="white" class="rounded-sm no-scroll" height="auto">
              <div class="dialog-card-auto pa-8 d-flex flex-column">
                <v-form v-model="movementForm">
                  <span class="form-label">Categoría</span>
                  <v-row class="d-flex align-end mb-2">
                    <v-col cols="10" class="d-flex">
                      <v-select
                        v-model="newMovementForm.category"
                        :items="categories"
                        outlined
                        hide-details
                        dense
                        class="mt-2 self-center"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <div
                        :style="{
                          backgroundColor: newMovementForm.color,
                          height: '40px',
                          border: '2px solid #31384b',
                          borderRadius: '5px',
                        }"
                      ></div>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="newMovementForm.category == 'Movimiento'"
                    >
                      <span class="form-label">Tipo de movimiento</span>
                      <v-select
                        :items="categoryOptions[newMovementForm.category]"
                        v-model="newMovementForm.movementType"
                        outlined
                        hide-details
                        dense
                        class="mt-2 self-center"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <section
                    v-if="newMovementForm.category == 'Evento'"
                    class="mt-4"
                  >
                    <span class="form-label"> Evento </span>
                    <v-combobox
                      outlined
                      dense
                      class="mt-2"
                      :items="eventList"
                      v-model="eventSelected"
                    >
                    </v-combobox>
                    <span class="form-label">Grupo</span>
                    <utility-combo
                      :comboType="'IMEF_GROUP'"
                      :comboList="IMEF_GROUPS"
                      :defVal="{
                        text: newMovementForm.organizator,
                        value: newMovementForm.organizator,
                      }"
                      @setUtility="updateGroup"
                      dense
                      outlined
                    />
                    <span class="form-label"> Tipo de evento </span>
                    <v-select
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.eventType"
                      :items="IMEF_SERVICES"
                    />
                    <span class="form-label"> Acceso </span>
                    <v-combobox
                      outlined
                      dense
                      class="mt-0"
                      :items="eventSelected.tickets"
                      v-model="accessSelected"
                    >
                    </v-combobox>
                    <span class="form-label"> Puntos EPC </span>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      class="mt-2"
                      :rules="epcRules"
                      :min="0"
                      v-model="newMovementForm.points"
                    />
                    <span class="form-label"> CEPLAN Presencial </span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.ceplan"
                    />
                    <span class="form-label"> CEPLAN Virtual </span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.ceplanv"
                    />
                  </section>

                  <section
                    v-if="newMovementForm.category == 'Consejo Directivo'"
                  >
                    <span class="form-label">Grupo</span>
                    <utility-combo
                      :comboType="'IMEF_GROUP'"
                      :comboList="IMEF_GROUPS"
                      @setUtility="updateGroup"
                      dense
                      outlined
                    />
                    <span class="form-label">Año lectivo</span>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      class="mt-2"
                      v-model="newMovementForm.year"
                    />
                    <span class="form-label">Posición</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.position"
                    />
                  </section>

                  <section
                    v-if="newMovementForm.category == 'Consejo Consultivo'"
                  >
                    <span class="form-label">Grupo</span>
                    <utility-combo
                      :comboType="'IMEF_GROUP'"
                      :comboList="IMEF_GROUPS"
                      @setUtility="updateGroup"
                      dense
                      outlined
                    />
                    <span class="form-label">Año lectivo</span>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      class="mt-2"
                      v-model="newMovementForm.year"
                    />
                    <span class="form-label">Posición</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.position"
                    />
                  </section>

                  <section v-if="newMovementForm.category == 'Comité Técnico'">
                    <span class="form-label">Grupo</span>
                    <utility-combo
                      :comboType="'IMEF_GROUP'"
                      :comboList="IMEF_GROUPS"
                      @setUtility="updateGroup"
                      dense
                      outlined
                    />
                    <span class="form-label mt-2">Tipo de comité</span>
                    <v-select
                      outlined
                      dense
                      :items="IMEF_COMMITTEE"
                      v-model="newMovementForm.committeeType"
                    ></v-select>
                    <span class="form-label">Posición</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.position"
                    />
                    <span class="form-label">Año</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      type="number"
                      v-model="newMovementForm.year"
                    />
                    <span class="form-label">Tema</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.topic"
                    />
                    <!-- <span class="form-label">Puntos EPC</span>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mt-2"
                    :min="0"
                    v-model="newMovementForm.points"
                  />
                  <span class="form-label">CEPLAN</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.ceplan"
                  /> -->
                  </section>

                  <section v-if="newMovementForm.category == 'Contribución'">
                    <span class="form-label">Grupo</span>
                    <utility-combo
                      :comboType="'IMEF_GROUP'"
                      :comboList="IMEF_GROUPS"
                      @setUtility="updateGroup"
                      dense
                      outlined
                    />
                    <span class="form-label">Tipo de contribución</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.contributionType"
                    />

                    <span class="form-label">Participación</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.participation"
                    />

                    <span class="form-label">Puntos EPC</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      type="number"
                      :rules="epcRules"
                      :min="0"
                      v-model="newMovementForm.points"
                    />

                    <span class="form-label">Medio de publicación</span>
                    <v-text-field
                      outlined
                      dense
                      class="mt-2"
                      v-model="newMovementForm.publicationMedium"
                    />
                  </section>

                  <section class="d-flex flex-column">
                    <span class="form-label">Descripción</span>
                    <v-textarea
                      v-model="newMovementForm.description"
                      outlined
                      dense
                      height="112px"
                      class="mt-2"
                    ></v-textarea>

                    <span class="form-label">Fecha</span>
                    <v-menu
                      v-model="newMovementDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :rules="[(v) => !!v || 'El rango de fechas es inválido']"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="button mt-2"
                          outlined
                          large
                        >
                          <v-row align="center" class="px-2">
                            <v-icon
                              color="grey lighten-1"
                              size="20"
                              class="mr-2"
                              >mdi-calendar-outline</v-icon
                            >
                            <span
                              class="button-text grey--text text--lighten-1"
                            >
                              {{
                                newMovementForm.date != ''
                                  ? newMovementForm.date
                                  : 'Selecciona una fecha'
                              }}
                            </span>
                          </v-row>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="newMovementForm.date"
                        locale="es"
                        no-title
                      ></v-date-picker>
                    </v-menu>
                    <v-btn
                      class="mr-2 mt-4 button white--text mb-4"
                      large
                      :disabled="!movementForm"
                      color="#1b273e"
                      @click="createMovement()"
                    >
                      <span class="button-text white--text"
                        >Crear Movimiento</span
                      >
                    </v-btn>
                  </section>

                  <!-- <span class="form-label mt-4">Color</span>

                <v-row
                  class="ma-0 color-outline py-2 px-4 mt-2"
                  justify="center"
                  align="center"
                >
                  <div
                    class="color-activator"
                    :style="`background-color: ${newMovementForm.color}`"
                  ></div>
                </v-row> -->

                  <!-- <span class="form-label mt-4">Categoria</span>

                <v-select
                  v-model="newMovementForm.category"
                  :items="categories"
                  outlined
                  dense
                  class="mt-2"
                  @change="setCategoryColor()"
                ></v-select> -->
                  <hr />
                </v-form>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-row>

      <v-expansion-panels flat>
        <v-expansion-panel
          v-for="(day, i) in groupedMovements"
          :key="i"
          class="mb-2 pa-0 day-card rounded-lg"
        >
          <v-expansion-panel-header color="#f8fafc" class="rounded-lg">
            <v-row class="ma-0" align="center" justify="space-between">
              <div class="day-card-title">
                {{ day.day + ' de ' + monthName(day.month) + ', ' + day.year }}
              </div>
              <div class="d-flex align-center">
                <div
                  v-for="(color, index) in day.colors"
                  :key="index"
                  class="color-bar mr-1"
                  :style="`background-color: ${color}`"
                ></div>
                <v-icon class="mx-1" color="black" size="18"
                  >mdi-clipboard-text</v-icon
                >
                <span class="movements-counter mr-4">
                  {{ day.movements.length }}
                </span>
              </div>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f8fafc" class="rounded-lg">
            <!-- <v-hover v-slot="{ hover }"> -->
            <v-row
              v-for="movement in day.movements"
              :key="movement._id"
              class="ma-0 pa-2 py-3 mt-1 movement-card d-flex"
            >
              <div
                class="big-color-bar mr-4"
                :style="`background-color: ${movement.color}`"
              ></div>
              <div class="d-flex flex-column">
                <span
                  class="movement-title"
                  v-if="movement.category !== 'Movimiento'"
                  >{{ movement.category }}</span
                >
                <span class="movement-title" v-else>{{
                  movement.movementType
                }}</span>
                <span class="movement-description">{{
                  movement.description
                }}</span>
                <div class="d-flex">
                  <div v-for="(item, key, index) of movement" :key="index">
                    <div v-if="IMEF_MOVEMENTS_DICT[key]" class="mr-4">
                      <span class="movement-subtitle">{{
                        IMEF_MOVEMENTS_DICT[key] + ': '
                      }}</span>
                      <span class="movement-description"> {{ item }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div id="panel-actions" class="ml-auto d-flex align-center">
                <v-btn icon @click="showDeleteDialog(movement)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-row>
            <!-- </v-hover> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <personalized-dialog-vue
        :dialog="selectedMovementForDelete !== null"
        @discard="selectedMovementForDelete = null"
        @save="deleteMovement()"
      >
        <template #alertName> Eliminar Movimiento </template>
        <template #alertContent>
          <span>Estás segur que deseas eliminar este movimiento?</span>
        </template>
      </personalized-dialog-vue>
    </div>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { IMEF_MOVEMENTS_DICT } from '../../../share/imef';
import PersonalizedDialogVue from '../../shared/PersonalizedDialog.vue';
import UtilityCombo from '../../shared/UtilityCombo.vue';
export default {
  components: {
    UtilityCombo,
    PersonalizedDialogVue,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    selectedMember: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      IMEF_MOVEMENTS_DICT,
      IMEF_GROUPS: [],
      IMEF_SERVICES: [],
      IMEF_COMMITTEE: [],
      startDate: new Date(),
      endDate: new Date(),
      dateMenu: false,
      filterMenu: false,
      newMovementDateMenu: false,
      newMovementDialog: false,
      colorMenu: false,
      settedStartDate: false,
      groupSearch: null,
      groupEditing: null,
      prevGroupEditing: '',
      groupEditingIndex: -1,
      movementForm: false,
      eventSelected: '',
      accessSelected: null,
      selectedMovementForDelete: null,
      newMovementForm: {
        title: '',
        movementType: '',
        description: '',
        date: '',
        color: '#000',
        category: '',
        points: '',
        organizator: '',
        ceplan: '',
        ceplanv: '',
        eventType: '',
        contributionType: '',
        participation: '',
        publicationMedium: '',
        year: '',
        position: '',
        committeeType: '',
        topic: '',
      },
      // categories: [
      //   "Ingreso",
      //   "Baja",
      //   "Cambio de categoría",
      //   "Reingreso",
      //   "Cambio de grupo",
      //   "Fallecimiento",
      //   "Año de Gracia",
      //   "Evento",
      //   "Consejo Directivo",
      //   "Comité Técnico",
      //   "Contribución",
      // ],
      categories: [
        'Movimiento',
        'Evento',
        'Consejo Directivo',
        'Consejo Consultivo',
        'Comité Técnico',
        'Contribución',
      ],
      categoryOptions: {
        Movimiento: [
          'Ingreso',
          'Baja',
          'Cambio de categoría',
          'Reingreso',
          'Cambio de grupo',
          'Fallecimiento',
          'Año de Gracia',
        ],
      },
      categoriesColors: {
        Movimiento: '#FF5733',
        Evento: '#33FF57',
        'Consejo Directivo': '#3357FF',
        'Consejo Consultivo': '#FF33A1',
        'Comité Técnico': '#FF8C33',
        Contribución: '#33FFF5',
      },
      swatches: [],
      movements: [],
      filters: [],
      epcRules: [(v) => v >= 0 || 'El valor ingresado es inválido'],
    };
  },
  methods: {
    ...mapMutations('loader', ['loading', 'loaded']),
    ...mapMutations('notification', ['show']),
    ...mapActions('event', ['fetchEventsList']),
    setCategoryColor(category) {
      this.newMovementForm.color = this.categoriesColors[category];
    },
    formattedDate(date) {
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: 'short',
      });
    },

    showDeleteDialog(movement) {
      this.selectedMovementForDelete = movement;
    },

    async updateGroup({ newValue = '', updateList = false }) {
      this.newMovementForm.organizator = newValue;
      if (updateList) {
        await this.$_fetchImefUtilities();
      }
    },

    async $_fetchImefUtilities() {
      try {
        let response = await this.axios({
          method: 'GET',
          url: '/utilities/fetch-utilities',
          params: {
            utilityTypes: ['IMEF_GROUP', 'IMEF_EVENT', 'IMEF_COMMITTEE'],
          },
        });

        if (response.status == 200) {
          const {
            IMEF_GROUP = [],
            IMEF_EVENT = [],
            IMEF_COMMITTEE = [],
          } = response.data.utilityLoad;
          this.IMEF_GROUPS = IMEF_GROUP;
          this.IMEF_SERVICES = IMEF_EVENT;
          this.IMEF_COMMITTEE = IMEF_COMMITTEE;
        }
      } catch (error) {
        console.log(error);
      }
    },

    resetForm() {
      this.newMovementForm = {
        title: '',
        movementType: '',
        description: '',
        date: '',
        color: '#000',
        category: '',
        points: '',
        organizator: '',
        ceplan: '',
        eventType: '',
        contributionType: '',
        participation: '',
        publicationMedium: '',
        year: '',
        position: '',
        committeeType: '',
        topic: '',
      };
    },

    async resetFilters() {
      this.filters.splice(0, this.filters.length);
      this.filterMenu = false;
      await this.getMemberMovements();
    },

    async getMemberMovements() {
      this.loading();
      try {
        const response = await this.axios({
          method: 'POST',
          url: `/movements/${this.member.id}`,
          data: {
            startDate: this.startDate,
            endDate: this.endDate,
            filters: this.filters,
          },
        });

        this.movements.splice(0, this.movements.length);

        this.movements = JSON.parse(JSON.stringify(response.data.movements));
      } catch (error) {
        console.log(error);
      } finally {
        this.filterMenu = false;
        this.dateMenu = false;
        this.loaded();
      }
    },
    // endpoint to update
    async createMovement() {
      this.loading();
      try {
        const { id, headOffice = '' } = this.member;
        const response = await this.axios({
          method: 'POST',
          url: `/movements/create/${id}/${headOffice}`,
          data: this.newMovementForm,
        });

        if (response.data) {
          this.show({
            text: '¡Se ha cargado el movimiento del miembro correctamente!',
            color: 'success',
          });
          await this.getMemberMovements();
        }
      } catch (error) {
        console.log(error);
        this.show({
          text: 'Hubo un error en la carga del movimiento, por favor intenta nuevamente',
          color: 'error',
        });
      } finally {
        this.resetForm();
        this.newMovementDialog = false;
        this.loaded();
      }
    },

    async deleteMovement() {
      try {
        this.loading();
        const movementId = this.selectedMovementForDelete?._id || null;
        if (movementId == null) throw new Error();
        const response = await this.axios({
          method: 'DELETE',
          url: '/movements/',
          data: {
            movementId,
          },
        });
        if (response.status == 200) {
          this.show({
            color: 'success',
            text: 'El movimiento se ha eliminado exitosamente',
          });
        }
      } catch (error) {
        this.show({
          text: 'Error: no se pudo eliminar este movimiento, por favor intentar nuevamente',
          color: 'error',
        });
      } finally {
        this.selectedMovementForDelete = null;
        await this.getMemberMovements();
        this.loaded();
      }
    },

    monthName(month) {
      let months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];

      return months[month];
    },
  },

  computed: {
    ...mapState('event', ['eventList']),
    formattedDateRange() {
      return `${this.formattedDate(this.startDate)} - ${this.formattedDate(
        this.endDate
      )}`;
    },

    truncatedName() {
      let name = this.member.name + ' ' + this.member.firstLastname;
      if (name.length > 25) {
        return name.substring(0, 25) + '...';
      }
      return name;
    },

    groupedMovements() {
      const groupedMovements = [];
      const dateGroups = new Map();

      if (this.movements.length === 0) return groupedMovements;

      for (const movement of this.movements) {
        const date = new Date(movement.date);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const movementData = { ...movement, dialog: false };
        const dateKey = `${day}-${month}-${year}`;

        if (dateGroups.has(dateKey)) {
          const existingGroup = dateGroups.get(dateKey);
          existingGroup.movements.push(movementData);

          if (!existingGroup.colors.includes(movement.color)) {
            existingGroup.colors.push(movement.color);
          }
        } else {
          const newGroup = {
            day,
            month,
            year,
            date,
            movements: [movementData],
            colors: [movement.color],
          };
          groupedMovements.push(newGroup);
          dateGroups.set(dateKey, newGroup);
        }
      }

      groupedMovements.sort((a, b) => b.date - a.date);

      return groupedMovements;
    },

    dateRange: {
      get() {
        // Return an array with the start and end date
        // dates must be in the format 'YYYY-MM-DD'

        let startDate =
          this.startDate.getFullYear() +
          '-' +
          (this.startDate.getMonth() + 1 < 10 ? '0' : '') +
          (this.startDate.getMonth() + 1) +
          '-' +
          (this.startDate.getDate() < 10 ? '0' : '') +
          this.startDate.getDate();

        let endDate =
          this.endDate.getFullYear() +
          '-' +
          (this.endDate.getMonth() + 1 < 10 ? '0' : '') +
          (this.endDate.getMonth() + 1) +
          '-' +
          (this.endDate.getDate() < 10 ? '0' : '') +
          this.endDate.getDate();

        return [startDate, endDate];
      },
      async set(value) {
        if (this.settedStartDate) {
          let endDateArray = value[0].split('-');
          this.endDate = new Date(
            endDateArray[0],
            endDateArray[1] - 1,
            endDateArray[2]
          );

          await this.getMemberMovements();
        } else {
          let startDateArray = value[0].split('-');
          this.startDate = new Date(
            startDateArray[0],
            startDateArray[1] - 1,
            startDateArray[2]
          );
          this.endDate = this.startDate;
        }

        this.settedStartDate = !this.settedStartDate;
      },
    },

    movementsColors() {
      let colors = [];

      for (let movement of this.movements) {
        if (!colors.includes(movement.color)) {
          colors.push(movement.color);
        }
      }

      //group in arrays of 3
      colors = colors.reduce((acc, curr, i) => {
        if (i % 3 === 0) acc.push([]);
        acc[acc.length - 1].push(curr);
        return acc;
      }, []);

      return colors;
    },
  },

  watch: {
    'newMovementForm.category': {
      async handler(newCategory) {
        this.setCategoryColor(newCategory);
      },
      deep: true,
    },

    eventSelected: {
      deep: true,
      handler(event) {
        this.updateGroup(event?.movementSetup?.organizator || '');
        this.newMovementForm.eventType = event?.movementSetup?.eventType || '';
      },
    },

    accessSelected: {
      deep: true,
      handler(access) {
        this.newMovementForm.points = access.imefPoints;
        this.newMovementForm.ceplan =
          [...access.ticketCeplan].find(
            (element) => element.item == 'PRESENTIAL'
          )?.value || 'N/A';
        this.newMovementForm.ceplanv =
          [...access.ticketCeplan].find((element) => element.item == 'VIRTUAL')
            ?.value || 'N/A';
      },
    },

    async selectedMember() {
      if (this.selectedMember == this.member.id) {
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);

        await this.getMemberMovements();
      }
    },
  },

  async created() {
    await this.fetchEventsList();
    this.startDate.setDate(this.startDate.getDate() - 30);
    await this.getMemberMovements();
    await this.$_fetchImefUtilities();
  },
};
</script>

<style>
.dialog-card {
  height: 80vh;
  padding: 40px 64px;
  font-family: Montserrat, sans-serif;
}

.dialog-card-auto {
  height: auto;
  padding: 40px 64px;
  font-family: Montserrat, sans-serif;
}

.v-dialog {
  background-color: white;
  overflow-y: auto; /* Permitir desplazamiento vertical */
  overflow-x: hidden; /* Ocultar desplazamiento horizontal */
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
}

.v-dialog::-webkit-scrollbar {
  display: none; /* Ocultar scroll */
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
}

.date-range {
  font-size: 16px;
  font-weight: 700;
  color: #a5adb7;
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  letter-spacing: normal;
  text-transform: capitalize;
}

.button {
  border-color: #c1c7cf;
}

.day-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: #f8fafc;
}

.day-card-title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.color-bar {
  width: 6px;
  height: 16px;
  border-radius: 4px;
}

.movements-counter {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.expand-button {
  border: 1px solid #ced7e3 !important;
  border-color: #b6bec9 !important;
  border-radius: 4px;
  background-color: white;
}

.movement-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: white;
}

.v-expansion-panel-content__wrap {
  padding: 8px 4px 4px 4px;
}

.big-color-bar {
  width: 6px;
  height: auto;
  border-radius: 4px;
}

.movement-title {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.movement-subtitle {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.movement-description {
  font-size: 11px;
  font-weight: 500;
  color: #858484;
}

.movements-header {
  position: sticky !important;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.color-outline {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}

.color-activator {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background-color: rgb(19, 19, 117);
}

.filter-button {
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}

.filter-item {
  border-bottom: 1px solid #e0e0e0;
}
</style>
